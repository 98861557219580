import gql from "graphql-tag"

export const customerDetailsQuery = gql`
  query customerDetails {
    customerDetails @client {
      email
      lastTipAmount
      snapscanRef
    }
  }
`

export const addOrUpdateCustomerMutation = gql`
  mutation addOrUpdateCustomer($input: CustomerDetails!) {
    addOrUpdateCustomer(input: $input) @client
  }
`

export const addOrUpdateRatingMutation = gql`
  mutation addRating($input: CreateRatingInput!) {
    addRating(input: $input) {
      id
    }
  }
`

export const addOrUpdateCommentMutation = gql`
  mutation addComment($input: CreateCommentInput!) {
    addComment(input: $input) {
      id
    }
  }
`


export const tippeeByMasterPassCodeMutation = gql`
mutation tippeeByMasterPassCode($code: String!) {
  tippeeByMasterPassCode(code: $code) {
    id
  }
}
`

export const TIPPEE_QUERY = gql`
  query tippee($id: String!) {
    tippee(id: $id) {
      id
      firstName
      shortFullName
      profileImageUrl
      location {
        name
      }
      bio
      rating
    }
  }
`

export const SUCCESSFUL_PAYMENT_BY_PROCESSOR_QUERY = gql`
  query paymentByProcessorId($id: String!, $processor: String!) {
    paymentByProcessorId(id: $id, processor: $processor) {
      id
    }
  }
`

export const SUCCESSFUL_PAYMENT_QUERY = gql`
  query payment($id: String!) {
    payment(id: $id) {
      id
      totalInCents
      email
      tippee {
        id
        shortFullName
        profileImageUrl
        location {
          name
        }
        bio
        rating
      }
      tipper {
        id
      }
    }
  }
`

export const SUCCESSFUL_SNAPSCAN_QUERY = gql`
  query paymentBySnapscanRef($id: String!) {
    paymentBySnapscanRef(id: $id) {
      payment {
        id
        totalInCents
        email
        processorTxId
        tippee {
          id
          firstName
          lastName
          profileImageUrl
          location {
            name
          }
          bio
          rating
        }
        tipper {
          id
        }
      }
    }
  }
`

export const CREATE_MASTERPASS_TX = gql`
mutation masterpassCreateTx($data: CreateMasterpassTxInput!) {
  masterpassCreateTx(data: $data)
}
`

export const CREATE_SNAPSCAN_TX = gql`
mutation snapscanCreateTx($data: CreateSnapscanTxInput!) {
  snapscanCreateTx(data: $data) {
    url
    ref
  }
}
`