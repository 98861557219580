import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup'
import { Container } from "@material-ui/core";
import Header from 'components/Header/Header'
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import BlueBar from 'components/Buttons/BlueBar'
import CircularProgress from '@material-ui/core/CircularProgress';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { navigate } from "@reach/router"

export default function EditPassword(props) {

  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      padding: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '@media (min-width: 600px)': {
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        border: '2px solid #E5E5E5',
      }
    },
    container: {
      width: '100%',
      height: '100%',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 300,
    },
    formHeader: {
      borderBottom: '2px #E5E5E5 solid',
      height: '62px',
      display: 'flex',
      flexDirection: 'row-reverse',
      fontSize: '22px',
      alignItems: 'center',
    },
    form: {
      marginTop: '16px',
      marginBottom: '16px',
      flexGrow: 1,
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginLeft: '16px',
      marginRight: '16px'
    },
    button: {
      marginBottom: '16px'
    },
    spinner: {
      color: 'gray'
    },
    field: {
      fontSize: '22px',
      lineHeight: '24px',
    }
  }));
  
  const classes = useStyles();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => {
      return !showPassword
    })
  };

  const handleClickShowOldPassword = () => {
    setShowOldPassword((showOldPassword) => {
      return !showOldPassword
    })
  };

  const handleClickShowPasswordConfirmation = () => {
    setShowPasswordConfirmation((showPasswordConfirmation) => {
      return !showPasswordConfirmation
    })
  };

  const FormValidation = Yup.object().shape({
    oldPassword: Yup.string().required('Password is required'),
    password: Yup.string().required('Password is required'),
    passwordConfirmation: Yup.string()
      .required('Password confirmation is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  })
  
  return (
    <Container maxWidth="sm" className={classes.mainContainer}>
      <Header size="std" color="primary" headerText="Edit profile"/>
      <Formik
        initialValues={{
          oldPassword: '',
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={FormValidation}
        onSubmit={(values) => {
          console.log(values)
          setTimeout(() => {
            navigate(`/account/profile?saved=true`)
          }, 2000);
        }}
      >
        {({ submitForm, isSubmitting, isValid, dirty }) => (
          <div className={classes.formContainer}>
            <Form className={classes.form}>
              <Field
                component={TextField}
                name="oldPassword"
                label="Old Password"
                fullWidth
                color="secondary"
                type={showOldPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowOldPassword}
                      >
                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  className: classes.field
                }}
              />
              <Field
                component={TextField}
                name="password"
                label="Password"
                fullWidth
                color="secondary"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  className: classes.field
                }}
              />
              <Field
                component={TextField}
                name="passwordConfirmation"
                label="Confirm Password"
                fullWidth
                color="secondary"
                type={showPasswordConfirmation ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirmation}
                      >
                        {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  className: classes.field
                }}
              />
            </Form>
            <BlueBar startIcon={isSubmitting && <CircularProgress className={classes.spinner} size='36px'/>} className={classes.button} onClick={submitForm} width="full" disabled={!(isValid && dirty)}>
              <div>
                {isSubmitting ? 'Setting new password' : 'Set new password'}
              </div>
            </BlueBar>
          </div>
        )}
      </Formik>
    </Container>
)}
