import React, { useEffect, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks'
import { SUCCESSFUL_PAYMENT_QUERY } from 'gql/queries'
import TipLoading from 'components/Misc/TipLoading'
import Url from 'url-parse'
import TipSuccess from 'components/Tip/TipSucess'

export default function TipSuccessGeneric(props) {

  const { loading, error, data, refetch } = useQuery(SUCCESSFUL_PAYMENT_QUERY,
    {
      variables: { id: props.transactionId },
    })

  const [retryCounter, setRetryCounter] = React.useState(10)
  const countRef = useRef(retryCounter)
  countRef.current = retryCounter

  useEffect(() => {
    if (error) {
      if (countRef.current === 0) {
        var url = new Url(window.location.href,true);
        const currentUrl = url.host + url.pathname.substr(0,url.pathname.lastIndexOf('/'))
        window.location.href = url.protocol + '//' + currentUrl.replace('success','failed')
      } else {
        const timer = setTimeout(() => {
          setRetryCounter(countRef.current-1)
          refetch()
          .catch( () => {
          })
        }, 1000);
        return () => clearTimeout(timer);
      }
    }
    // eslint-disable-next-line
  }, [retryCounter, error]);

  if ( loading || error )
    return (
      <TipLoading open={true}/>
    )
  
  const payment = data.payment

  const tippee = payment.tippee
  
  return (
    <TipSuccess tippee={tippee} payment={payment}/>
)}
