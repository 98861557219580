import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Avatar } from '@material-ui/core';
import Header from 'components/Header/Header'
import { t4cBlueColor } from 'assets/jss/styles';
import Stars from 'components/Misc/Stars'
import QuickTip from 'components/Misc/QuickTip'
import { Helmet } from "react-helmet";
import BlueBar from 'components/Buttons/BlueBar'
import PaymentMethod from 'components/Misc/PaymentMethod'
import CustomTipSheet from 'components/Misc/CustomTipSheet'
import ChoosePaymentMethodModal from 'components/Misc/ChoosePaymentMethodModal'
import TipLoading from 'components/Misc/TipLoading'
import { TIPPEE_QUERY, CREATE_MASTERPASS_TX, CREATE_SNAPSCAN_TX, customerDetailsQuery, addOrUpdateCustomerMutation } from 'gql/queries'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import Person from '@material-ui/icons/Person'

export default function Tip(props) {

  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      padding: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '@media (min-width: 600px)': {
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        border: '2px solid #E5E5E5',
      },
      color: '#FFFFFF',
      fontFamily: theme.typography.fontFamily
    },
    contents: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    tippeeContainer: {
      flexBasis: "40%",
      backgroundColor: t4cBlueColor,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tipActions: {
      flexBasis: "60%",
      display: 'flex',
      flexDirection: 'column'
    },
    tippeeImage: {
      height: 80,
      width: 80,
      borderRadius: '50%',
      border: '2px white solid',
    },
    tippeeName: {
      marginTop: '20px',
      fontSize: '32px',
      lineHeight: '20px',
      fontWeight: '300',
    },
    tippeeInfo: {
      marginTop: '16px',
      fontSize: '20px',
      lineHeight: '20px',
      fontWeight: '300',
    },
    starContainer: {
      marginTop: '16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '@media (max-height: 670px)': {
      tippeeImage: {
        display: 'none'
      },
      tippeeName: {
        marginTop: 'unset',
      },
      starContainer: {
        marginTop: '10px',
        marginBottom: '10px'
      }
    },
    quickTips: {
      marginTop: '12px',
      overflowX: 'scroll',
      overflowY: 'hidden',
      whiteSpace: 'nowrap',
      paddingBottom: '24px',
      paddingTop: '16px',
      textAlign: 'center',
    },
    customTip: {
      height: '56px',
      width: '90%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.secondary.main,
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '20px'
    },
    customTipButton: {
      fontSize: '20px',
      fontFamily: theme.typography.fontFamily,
      textTransform: 'none',
    },
    spinner: {
      color: 'gray'
    },
    spaceFiller: {
      flexGrow: 1
    },
    tipButton: {
      marginTop: '8px',
      marginBottom: '16px',
    },
    filledCustomTip: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      color: 'black',
      fontSize: 28,
      borderBottom: '2px #E5E5E5 solid',
    },
    form: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    profileCircle: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
      height: '80px',
      width: '80px',
    },
    profileIcon: {
      height: '65px',
      width: '65px',
    },
  }));
  
  const classes = useStyles();

  const EmailValidation = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required('Required'),
  })

  const { loading, error, data } = useQuery(TIPPEE_QUERY,
    {
      variables: {
        id: props.tippee,
      },
    })

  const { loading: loadingCustomer, error: customerError, data: customer } = useQuery(customerDetailsQuery);

  const [addOrUpdateCustomer] = useMutation(addOrUpdateCustomerMutation);

  const [createMasterpassTx] = useMutation(CREATE_MASTERPASS_TX)
  const [createSnapscanTx] = useMutation(CREATE_SNAPSCAN_TX)

  const [chosenTip, setChosenTip] = React.useState();
  const [openCustomTipModal, setOpenCustomTipModal] = React.useState(false);
  const [openChoosePaymentMethodModal, setChoosePaymentMethodModal] = React.useState(false);
  const [customTip, setCustomTip] = React.useState();
  const [chosenPaymentMethod, setChosenPaymentMethod] = React.useState(0);

  const tipvalues = [
    5,
    10,
    20,
    30,
    50
  ]

  const currency = 'R'

  const paymentMethods = [

    {
      type: 'snapscan',
      lastFour: null,
    },
    {
      type: 'masterpass',
      lastFour: null,
    },
  ]

  const quickTips = tipvalues.map((number) =>
    <QuickTip selected={number === chosenTip} key={number} value={number} onClick={() => choosePresetTip(number)} currency={currency}/>
  );

  const chooseCustomTip = () => {
    setChosenTip(null)
    setOpenCustomTipModal(true)
  }

  const choosePresetTip = (number) => {
    setChosenTip(number)
    setCustomTip(null)
  }

  const onCustomTipModalClose = (amount) => {
    setCustomTip(amount/100)
    setOpenCustomTipModal(false)
  }

  const onChoosePaymentMethodModalClose = () => {
    setChoosePaymentMethodModal(false)
  }

  const changePaymentMethod = (paymentMethodId) => {
    setChosenPaymentMethod(paymentMethodId)
    onChoosePaymentMethodModalClose()
  }

  const doTip = (values) => {
    addOrUpdateCustomer({
      variables: {
        input: {
          email: values.email,
          lastTipAmount: chosenTip ?? customTip,
          snapscanRef: ''
        }
      },
    })
    .then(() => {
      customer.customerDetails.email = values.email
      customer.customerDetails.lastTipAmount = chosenTip ?? customTip
      customer.customerDetails.snapscanRef = ''
      switch (paymentMethods[chosenPaymentMethod].type) {
        case 'snapscan':
          return paySnapscan(values.email)
        case 'masterpass':
          return payMasterpass(values.email)
        default:
          return paySnapscan(values.email)
      }
      
    })
    .catch((error) => {
      console.log(error)
    })
  }

  if (loading || loadingCustomer)
    return (
      <TipLoading open={true}/>
    )

  if (error || customerError)
    return (
      <Container maxWidth="sm">
        Cannot find tippee
      </Container>
    )

  const tippee = data.tippee

  const payMasterpass = (email) => {
    let currentUrl = window.location.href
    if (currentUrl.substr(-1) !== '/') currentUrl = currentUrl + '/' 
    createMasterpassTx({
      variables: {
        data: {
          callbackUrl: `${currentUrl}masterpass/redirect`,
          amount: chosenTip ?? customTip,
          tippeeId: tippee.id,
          email: email
        },
      },
    })
    .then(function (response) {
      window.location.href = response.data.masterpassCreateTx;
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const paySnapscan = (email) => {
    let currentUrl = window.location.href
    if (currentUrl.substr(-1) !== '/') currentUrl = currentUrl + '/'
    let url = undefined
    createSnapscanTx({
      variables: {
        data: {
          callbackUrl: `${currentUrl}redirect`,
          amount: chosenTip ?? customTip,
          tippeeId: tippee.id,
          email: email
        },
      },
    })
    .then(function (response) {
      url = response.data.snapscanCreateTx.url;
      return addOrUpdateCustomer({
        variables: {
          input: {
            ...customer.customerDetails,
            snapscanRef: response.data.snapscanCreateTx.ref
          }
        },
      })
    })
    .then( () => {
      window.location.href = url
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  
  return (
    <React.Fragment>
      <Container maxWidth="sm" className={classes.mainContainer}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Tip4Change</title>
            <meta name="theme-color" content={t4cBlueColor}/>
        </Helmet>
        <Header backUrl="/" size="std" color="primary" headerText="Tipping"/>
        <div className={classes.contents}>
          <div className={classes.tippeeContainer}>
            {tippee.profileImageUrl ?
            (<img className={classes.tippeeImage} src={tippee.profileImageUrl} alt='Tippee' />) :
            (
              <Avatar className={classes.profileCircle}>
                <Person className={classes.profileIcon} />
              </Avatar>
            )}
            <div className={classes.tippeeName}>{tippee.shortFullName}</div>
            <div className={classes.tippeeInfo}>{tippee.bio}</div>
            <div className={classes.starContainer}>
              <Stars rating={tippee.rating}/>
            </div>
          </div>
          <div className={classes.tipActions}>
            <div className={classes.quickTips}>
              {quickTips}
            </div>
            <div className={classes.customTip}>
              {customTip ?
              (
                <div className={classes.filledCustomTip}>
                  <Button className={classes.customTipButton} onClick={chooseCustomTip} color="secondary">Edit tip</Button>
                  <div>R {customTip.toFixed(2)}</div>
                </div>
              ) :
              (<Button className={classes.customTipButton} onClick={chooseCustomTip} color="secondary">Add your own tip</Button>)
              }
            </div>
            <div className={classes.spaceFiller}/>
            <Formik
              initialValues={{
                email: customer?.customerDetails?.email || '',
              }}
              validationSchema={EmailValidation}
              onSubmit={doTip}
            >
              {({ submitForm, isSubmitting, isValid, dirty }) => (
                <div>
                  <Form className={classes.form}>
                    <Field
                      component={TextField}
                      name="email"
                      type="email"
                      label="Email Address"
                      fullWidth
                      color="secondary"
                      autoFocus={customer?.customerDetails?.email ? false : true}
                      InputProps={{
                        className: classes.field
                      }}
                    />
                    <PaymentMethod cardType={paymentMethods[chosenPaymentMethod].type} lastFour={paymentMethods[chosenPaymentMethod].lastFour} changePaymentMethod={() => setChoosePaymentMethodModal(true)}/>
                    <BlueBar className={classes.tipButton} onClick={submitForm} width="full" disabled={isSubmitting || (!customTip && !chosenTip) || !isValid}>
                      <div>
                        Tip {tippee.firstName}
                      </div>
                    </BlueBar>
                  </Form>
                  <TipLoading open={isSubmitting}/>
                </div>
              )}
            </Formik>
          </div>
        </div>
        
      </Container>
      <CustomTipSheet handleClose={onCustomTipModalClose} currency='R' open={openCustomTipModal}/>
      <ChoosePaymentMethodModal
        handleClose={onChoosePaymentMethodModalClose}
        currency='R'
        open={openChoosePaymentMethodModal}
        paymentMethods={paymentMethods}
        chosenPaymentMethod={chosenPaymentMethod}
        changePaymentMethod={changePaymentMethod}/>
    </React.Fragment>
)}
