import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup'
import { Container } from "@material-ui/core";
import Header from 'components/Header/Header'
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import BlueBar from 'components/Buttons/BlueBar'
import CircularProgress from '@material-ui/core/CircularProgress';
import { navigate } from "@reach/router"

export default function Profile(props) {

  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      padding: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '@media (min-width: 600px)': {
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        border: '2px solid #E5E5E5',
      }
    },
    container: {
      width: '100%',
      height: '100%',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 300,
    },
    formHeader: {
      borderBottom: '2px #E5E5E5 solid',
      height: '62px',
      display: 'flex',
      flexDirection: 'row-reverse',
      fontSize: '22px',
      alignItems: 'center',
    },
    form: {
      marginTop: '16px',
      marginBottom: '16px',
      flexGrow: 1,
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginLeft: '16px',
      marginRight: '16px'
    },
    button: {
      marginBottom: '16px'
    },
    spinner: {
      color: 'gray'
    },
    field: {
      fontSize: '22px',
      lineHeight: '24px',
    }
  }));

  const profileEditFields = {
    'first-name': {
      label: 'First Name',
      type: 'text',
      name: 'firstName',
      validation: Yup.string().required('Required')
    },
    'last-name': {
      label: 'Last Name',
      type: 'text',
      name: 'lasttName',
      validation: Yup.string().required('Required')
    },
    'email': {
      label: 'Email',
      type: 'email',
      name: 'email',
      validation: Yup.string().email('Please enter a valid email').required('Required')
    },
  }
  
  const classes = useStyles();

  const FormValidation = Yup.object().shape({
    [profileEditFields[props.field].name]: profileEditFields[props.field].validation,
  })
  
  return (
    <Container maxWidth="sm" className={classes.mainContainer}>
      <Header size="std" color="primary" headerText="Edit profile"/>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
        }}
        validationSchema={FormValidation}
        onSubmit={(values) => {
          console.log(values)
          setTimeout(() => {
            navigate(`/account/profile?saved=true`)
          }, 2000);
        }}
      >
        {({ submitForm, isSubmitting, isValid, dirty }) => (
          <div className={classes.formContainer}>
            <Form className={classes.form}>
              <Field
                component={TextField}
                name={profileEditFields[props.field].name}
                type={profileEditFields[props.field].type}
                label={profileEditFields[props.field].label}
                fullWidth
                color="secondary"
                InputProps={{
                  className: classes.field
                }}
                autoFocus
              />
            </Form>
            <BlueBar startIcon={isSubmitting && <CircularProgress className={classes.spinner} size='36px'/>} className={classes.button} onClick={submitForm} width="full" disabled={!(isValid && dirty)}>
              <div>
                {isSubmitting ? 'Saving change' : 'Save change'}
              </div>
            </BlueBar>
          </div>
        )}
      </Formik>
    </Container>
)}
