import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import visa from 'assets/img/dark-card-icons/visa.png';
import mastercard from 'assets/img/dark-card-icons/mastercard.png';
import maestro from 'assets/img/dark-card-icons/maestro.png';
import masterpass from 'assets/img/dark-card-icons/masterpass.png';
import generic from 'assets/img/dark-card-icons/generic.png';
import snapscan from 'assets/img/dark-card-icons/snapscan.png';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

export default function PaymentMethod(props) {
  
  const useStyles = makeStyles((theme) => ({
      container: {
        color: '#000000',
        display: 'flex',
        alignItems: 'center',
        fontSize: '22px',
        height: '56px',
        width: '100%',
      },
      logo: {
        height: '24px',
        paddingRight: '16px'
      },
      changePaymentMethod: {
        fontSize: '22px',
        textTransform: 'none',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.secondary.main,
      },
      methodText: {
        flexGrow: 1,
        textAlign: 'left',
        fontFamily: theme.typography.fontFamily,
      },
      radio: {
        padding: '16px',
        color: theme.palette.secondary.main,
      }
    }
  ));

  const classes = useStyles();

  const cardLogo = (brand) => {
    switch (brand) {
      case 'visa':
        return visa
      case 'mastercard':
        return mastercard
      case 'maestro':
        return maestro
      case 'masterpass':
        return masterpass
      case 'snapscan':
        return snapscan
      default:
        return generic
    }
  }

  const {
    className,
    cardType,
    lastFour,
    changePaymentMethod,
    buttonType,
    paymentMethodKey,
    selected
  } = props;

  const button = (buttonType) => {
    switch (buttonType) {
      case 'change':
        return <div className={classes.changePaymentMethod} color="secondary">Change</div>
      case 'radio':
      return selected ? <RadioButtonCheckedIcon className={classes.radio}/> : <RadioButtonUncheckedIcon className={classes.radio}/>
      default:
        return <div className={classes.changePaymentMethod} color="secondary">Change</div>
    }
  }

  const pmClasses = classNames({
    [className]: className,
    [classes.container]: true,
  });

  return (
    <ButtonBase className={pmClasses} onClick={() => changePaymentMethod(paymentMethodKey)}>
      <img className={classes.logo} alt='card type' src={cardLogo(cardType)}/>
      <div className={classes.methodText}>{cardType[0].toUpperCase()+cardType.slice(1)}{lastFour && ' •••• ' + lastFour}</div>
      {button(buttonType)}
    </ButtonBase>
  );
}

PaymentMethod.propTypes = {
  className: PropTypes.string,
  cardType: PropTypes.string,
  lastFour: PropTypes.string,
  changePaymentMethod: PropTypes.func,
  buttonType: PropTypes.string,
  paymentMethodKey: PropTypes.number,
  selected: PropTypes.bool,
};
