import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import StarRate from "@material-ui/icons/StarRate";
import StarRateOutlined from "@material-ui/icons/StarRateOutlined";
import { t4cYellowColor } from "assets/jss/styles";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function RatingStars(props) {

  const {
    onRate,
    disable
  } = props;
  
  const useStyles = makeStyles((theme) => ({
      yellow: {
        color: t4cYellowColor
      },
      star: {
        fontSize: '60px'
      },
      gray: {
        color: '#E5E5E5'
      },
      loading: {
        height: '65px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    }
  ));

  const classes = useStyles();

  const [rating, setRating] = React.useState(0);

  const rate = (rating) => {
    setRating(rating)
    onRate(rating)
  }

  const createStars = (rating) => {
    let stars = []

    if (disable) {
      return (
        <div className={classes.loading}>
          <CircularProgress  thickness={5}/>
        </div>
      )
    }

    // Outer loop to create parent
    for (let i = 1; i <= rating; i++) {
      stars.push(<StarRate key={i} onClick={() => rate(i)} className={classes.yellow + ' ' + classes.star}/>)
    }
    for (let i = rating+1; i <= (5); i++) {
      stars.push(<StarRateOutlined key={i}  onClick={() => rate(i)} className={classes.gray + ' ' + classes.star}/>)
    }
    return stars
  }


  return (
    <React.Fragment>
      {createStars(rating)}
    </React.Fragment>
  );
}

RatingStars.propTypes = {
  onRate: PropTypes.func.isRequired,
  disable: PropTypes.bool
};
