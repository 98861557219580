import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Modal, Slide } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup'
import CircularProgress from '@material-ui/core/CircularProgress';
import BlueBar from 'components/Buttons/BlueBar'
import { addOrUpdateCommentMutation } from 'gql/queries'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack';

export default function CommentModal(props) {

  const useStyles = makeStyles((theme) => ({
    modalContainer: {
      width: '100%',
      height: '100%',
      bottom: '0px',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: theme.typography.fontFamily,
    },
    loadingContainer: {
      backgroundColor: 'white',
      height: '100%',
      paddingLeft: '16px',
      paddingRight: '16px',
      display: 'flex',
      flexDirection: 'column',
    },
    formHeader: {
      borderBottom: '2px #E5E5E5 solid',
      height: '62px',
      display: 'flex',
      flexDirection: 'row-reverse',
      fontSize: '22px',
      alignItems: 'center',
    },
    form: {
      marginTop: '16px',
      marginBottom: '16px',
      flexGrow: 1,
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    button: {
      marginBottom: '16px'
    },
    spinner: {
      color: 'gray'
    },
    field: {
      fontSize: '22px',
      lineHeight: '24px',
    }
  }
  ));

  const classes = useStyles();

  const CommentValidation = Yup.object().shape({
    comment: Yup.string().required('Required').max(300, 'Too Long!'),
  })

  const [addOrUpdateComment] = useMutation(addOrUpdateCommentMutation);
  const [comment, setComment] = React.useState('');

  const { enqueueSnackbar } = useSnackbar();

  const {
    open,
    handleClose,
    payment,
  } = props;

  return (
    <Modal
      open={open}
      aria-labelledby="Processing"
      aria-describedby="The tip is processing"
      className={classes.modalContainer}
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <div className={classes.loadingContainer}>
          <div className={classes.formHeader}>
            <IconButton onClick={() => handleClose(comment)} aria-label="back" color="inherit">
              <Close />
            </IconButton>
          </div>
          <Formik
            initialValues={{
              comment: comment,
            }}
            validationSchema={CommentValidation}
            onSubmit={(values, { setSubmitting }) => {
              setComment(values.comment)
              addOrUpdateComment({
                variables: {
                  input: {
                    comment: values.comment,
                    tippeeId: payment.tippee.id,
                    tipperId: payment.tipper.id,
                    paymentId: payment.id
                  }
                },
              })
              .then( () => {
                enqueueSnackbar('Thank you for your comment!', { 
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                });
                handleClose(comment)
              })
              .catch((error) => {
                console.log(error)
                enqueueSnackbar(error.message, { 
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                });
              })
              .finally(() => {
                setSubmitting(false)
              })
            }}
          >
            {({ submitForm, isSubmitting, isValid, dirty }) => (
              <div className={classes.formContainer}>
                <Form className={classes.form}>
                  <Field
                    component={TextField}
                    name="comment"
                    type="text"
                    label="Leave a comment"
                    fullWidth
                    multiline
                    color="secondary"
                    InputProps={{
                      className: classes.field,
                    }}
                    autoFocus
                  />
                </Form>
                <BlueBar startIcon={isSubmitting && <CircularProgress className={classes.spinner} size='36px'/>} className={classes.button} onClick={submitForm} width="full" disabled={!(isValid && dirty)}>
                  <div>
                    {isSubmitting ? 'Adding comment' : 'Add comment'}
                  </div>
                </BlueBar>
              </div>
            )}
          </Formik>
        </div>
      </Slide>
    </Modal>
  );
}

CommentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  payment: PropTypes.object.isRequired,
};
