import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
// material-ui components
import Button from "@material-ui/core/Button";

export default function T4CButton(props) {

  const useStyles = makeStyles((theme) => ({
      t4cButton: {
        borderRadius: 0,
      },
      loginButton: {
        position: 'relative',
        height: '36px',

        flex: 'none',
        alignSelf: 'center',
        boxSizing: 'border-box',
        '& span': {
          height: '100%',
        },
        '& div': {
          fontSize: '20px',
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Betm',
          fontWeight: '400',
          lineHeight: '16px',
          letterSpacing: '-0.01em',
          textTransform: 'none',
        },
        '& svg': {
          position: 'absolute',
          width: '24px',
          height: '24px',
          left: '6px',
          top: 'calc(50% - 24px/2)',
        }
      },
      whiteOutline: {
        background: '#FFFFFF',
        border: '2px solid #E5E5E5',
      },
      secondary: {
        background: theme.palette.secondary.main,
        color: '#FFFFFF',
        height: '48px'
      },
      std: {
        width: '312px',
      },
      resposiveDouble: {
        width: '45%',
      },
      full: {
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
      },
      fullWithMargin: {
        width: 'calc(100% - 48px)',
        marginLeft: '24px',
        marginRight: '24px',
      }
    }
  ));

  const classes = useStyles();

  const {
    type,
    className,
    width,
    muiClasses,
    children,
    ...rest
  } = props;
  const btnClasses = classNames({
    [className]: className,
    [classes.t4cButton]: true,
    [classes.loginButton]: true,
    [classes[width]]: width,
    [classes[type]]: type,
  });
  return (
    <Button {...rest} variant="contained" classes={muiClasses} className={btnClasses}>
      {children}
    </Button>
  );
}

T4CButton.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOf([
    "std",
    "resposiveDouble",
    "full",
    "fullWithMargin"
  ]),
  type: PropTypes.oneOf([
    "whiteOutline",
    "secondary",
  ]),
  // use this to pass the classes props from Material-UI
  muiClasses: PropTypes.object,
  children: PropTypes.node
};

T4CButton.defaultProps = {
  type: 'whiteOutline',
};
