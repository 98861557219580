import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { IconButton } from "@material-ui/core";
import { navigate } from "@reach/router";

export default function Header(props) {
  
  const useStyles = makeStyles((theme) => ({
      header: {
        position: 'static',
        color: '#FFFFFF',
        display: 'flex',
      },
      std: {
        height: '56px',
      },
      lg: {
        height: '256px',
      },
      primary: {
        backgroundColor: theme.palette.secondary.main
      },
      leftButton: {
        height: '56px',
        color: '#FFFFFF',
      },
      heading: {
        marginLeft: '10px',
        height: '56px',
        fontSize: '22px',
        lineHeight: '20px',
        paddingTop: '18px',
        fontFamily: theme.typography.fontFamily
      }
    }
  ));

  const classes = useStyles();

  const {
    color,
    size,
    className,
    children,
    headerText,
    backUrl,
    ...rest
  } = props;
  const headerClasses = classNames({
    [className]: className,
    [classes.header]: true,
    [classes[color]]: color,
    [classes[size]]: size,
  });

  const goBack = function () {
    if (backUrl) navigate(backUrl)
    else window.history.back()
  }

  return (
    <header {...rest} className={headerClasses}>
      <IconButton onClick={goBack} aria-label="back" color="inherit" className={classes.leftButton}>
        <ArrowBack />
      </IconButton>
      <div className={classes.heading}>
        {headerText}
      </div>
      {children}
    </header>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "white",
  ]),
  size: PropTypes.oneOf([
    "std",
    "lg",
  ]),
  className: PropTypes.string,
  headerText: PropTypes.string,
  children: PropTypes.node,
  backUrl: PropTypes.string
};
