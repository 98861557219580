import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Modal, Slide } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import PaymentMethod from 'components/Misc/PaymentMethod'

export default function ChoosePaymentMethodModal(props) {
  
  const useStyles = makeStyles((theme) => ({
      modalContainer: {
        width: '100%',
        height: '100%',
        bottom: '0px',
        display: 'flex',
        flexDirection: 'column',
        color: '#000000',
        fontFamily: theme.typography.fontFamily
      },
      formContainer: {
        backgroundColor: 'white',
        marginTop: 'auto',
      },
      formHeader: {
        borderBottom: '2px #E5E5E5 solid',
        height: '62px',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '22px',
        alignItems: 'center',
        paddingLeft: '16px',
      },
      formHeaderText: {
        flexGrow: 1,
      },
      paymentMethodContainer: {
        paddingLeft: '16px',
      }
    }
  ));

  const classes = useStyles();

  const {
    open,
    handleClose,
    paymentMethods,
    changePaymentMethod,
    chosenPaymentMethod
  } = props;

  const paymentMethodElements = paymentMethods.map((value, index) =>
    <PaymentMethod key={index} cardType={value.type} lastFour={value.lastFour} buttonType='radio' selected={chosenPaymentMethod === index} changePaymentMethod={() => changePaymentMethod(index)}/>
  );

  return (
    
    <Modal
      open={open}
      aria-labelledby="Tip Method"
      aria-describedby="Choose your method of tipping"
      className={classes.modalContainer}
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <div className={classes.formContainer}>
          <div className={classes.formHeader}>
            <div className={classes.formHeaderText}>Tip Method</div>
            <IconButton onClick={() => handleClose(null)} aria-label="back" color="inherit" className={classes.leftButton}>
              <Close />
            </IconButton>
          </div>
          <div className={classes.paymentMethodContainer}>
            {paymentMethodElements}
          </div>
        </div>
      </Slide>
    </Modal>
    
  );
}

ChoosePaymentMethodModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  paymentMethods: PropTypes.array,
  changePaymentMethod: PropTypes.func,
  chosenPaymentMethod: PropTypes.number,
};
