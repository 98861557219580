import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import CheckCircle from "@material-ui/icons/CheckCircleOutlined";

export default function QuickTip(props) {
  
  const useStyles = makeStyles((theme) => ({
      container: {
        height: '80px',
        width: '80px',
        border: '2px solid #E5E5E5',
        marginLeft: '16px',
        display: 'inline-block',
        position: 'relative',
        '&:last-of-type': {
          marginRight: '16px',
        },
        cursor: 'pointer'
      },
      centering: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      },
      tipText: {
        fontSize: '20px',
        lineHeight: '16px',
        color: '#000000',
        fontWeight: '400',
        fontFamily: theme.typography.fontFamily
      },
      selected: {
        border: `2px solid ${theme.palette.secondary.main}`,
      },
      checkIcon: {
        color: theme.palette.secondary.main,
        top: '-15px',
        right: '-15px',
        position: 'absolute',
        fontSize: '26px',
        backgroundColor: 'white',
        padding: '1px',
      }
    }
  ));

  const classes = useStyles();

  const {
    className,
    value,
    currency,
    selected,
    ...rest
  } = props;

  const tipClasses = classNames({
    [className]: className,
    [classes.container]: true,
    [classes.selected]: selected,
  });

  return (
    <div {...rest} className={tipClasses}>
      {selected && <CheckCircle className={classes.checkIcon}/>}
      <div className={classes.centering}>
        <div className={classes.tipText}>{currency} {value.toFixed(2)}</div>
      </div>
    </div>
  );
}

QuickTip.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  currency: PropTypes.string,
  selected: PropTypes.bool,
};
