import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles';
import { Container, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import HighlightOff from '@material-ui/icons/HighlightOff';
import BlueBar from 'components/Buttons/BlueBar'
import { navigate } from "@reach/router"
import PaymentMethod from 'components/Misc/PaymentMethod'
import ChoosePaymentMethodModal from 'components/Misc/ChoosePaymentMethodModal'
import { CREATE_MASTERPASS_TX, TIPPEE_QUERY, customerDetailsQuery } from 'gql/queries'
import TipLoading from 'components/Misc/TipLoading'

export default function TipFailure(props) {

  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      padding: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '@media (min-width: 600px)': {
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        border: '2px solid #E5E5E5',
      },
      fontFamily: theme.typography.fontFamily
    },
    header: {
      height: '56px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '22px',
    },
    headerButton: {
      right: '0px',
      position: 'absolute',
      height: '56px',
    },
    redHeader: {
      backgroundColor: '#E33739',
      height: '345px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      color: '#FFFFFF',
      alignItems: 'center',
      position: 'relative',
    },
    whiteFooter: {
      backgroundColor: '#FFFFFF',
      flexBasis: '55%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '16px'
    },
    centerIcon: {
      fontSize: '110px',
    },
    tipAmount: {
      fontSize: '64px',
      lineHeight: '48px',
      fontWeight: 500,
      marginBottom: '40px'
    },
    tippeeImage: {
      height: 80,
      width: 80,
      borderRadius: '50%',
      border: '2px white solid',
    },
    tippeeName: {
      marginLeft: '20px',
      fontSize: '24px',
      lineHeight: '20px',
      fontWeight: '300',
    },
    tippeeBio: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '16px',
    },
    bottomButton: {
      marginBottom: '16px',
    },
    ratingDesc: {
      textAlign: 'center',
      fontSize: '24px',
      lineHeight: '20px',
      fontWeight: '300',
      marginBottom: '8px',
    },
    starContainer: {
      marginBottom: '16px',
      textAlign: 'center',
    },
    sorry: {
      fontSize: '32px',
      marginBottom: '16px'
    },
    sorryDesc: {
      fontSize: '24px',
      textAlign: 'center'
    },
    spaceFiller: {
      flexGrow: 1
    },
    
  }));
  
  const classes = useStyles();

  const { loading, error, data } = useQuery(TIPPEE_QUERY,
    {
      variables: {
        id: props.tippee,
      },
    })

  const [createTx] = useMutation(CREATE_MASTERPASS_TX)

  const { loading: loadingCustomer, error: customerError, data: customer } = useQuery(customerDetailsQuery);

  const [chosenPaymentMethod, setChosenPaymentMethod] = React.useState(0);
  const [openChoosePaymentMethodModal, setChoosePaymentMethodModal] = React.useState(false);
  const [tipLoading, setTipLoading] = React.useState(false);

  const handleClose = () => {
    navigate(`/`)
  }

  const onChoosePaymentMethodModalClose = () => {
    setChoosePaymentMethodModal(false)
  }

  const changePaymentMethod = (paymentMethodId) => {
    setChosenPaymentMethod(paymentMethodId)
    onChoosePaymentMethodModalClose()
  }

  const currency = 'R'

  const paymentMethods = [
    {
      type: 'masterpass',
      lastFour: null,
    },
    {
      type: 'snapscan',
      lastFour: null,
    }
  ]

  if ( loading || loadingCustomer )
    return (
      <TipLoading open={true}/>
    )

  if ( error || customerError )
    return (
      <Container maxWidth="sm">
        Cannot find tippee
      </Container>
    )

  const tippee = data.tippee

  const tryAgain = () => {
    setTipLoading(true)
    payMasterpass(customer?.customerDetails?.email)
  }

  const payMasterpass = (email) => {
    let currentUrl = window.location.href
    currentUrl = currentUrl.substr(0,currentUrl.indexOf('failed'))
    createTx({
      variables: {
        data: {
          callbackUrl: `${currentUrl}redirect`,
          amount: customer?.customerDetails?.lastTipAmount,
          tippeeId: tippee.id,
          email: email
        },
      },
    })
    .then(function (response) {
      console.log(response.data.masterpassCreateTx);
      window.location.href = response.data.masterpassCreateTx;
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  
  return (
    <React.Fragment>
      <Container maxWidth="sm" className={classes.mainContainer}>
        <div className={classes.grid + ' ' + classes.redHeader}>
          <div className={classes.header}>
            <IconButton onClick={() => handleClose(null)} aria-label="back" color="inherit" className={classes.headerButton}>
              <Close />
            </IconButton>
            <div className={classes.heading}>
              Something went wrong
            </div>
          </div>
          <HighlightOff className={classes.centerIcon}/>
          <div className={classes.tipAmount}>
            {currency} {(customer?.customerDetails?.lastTipAmount).toFixed(2)}
          </div>
        </div>
        <div className={classes.grid + ' ' + classes.whiteFooter}>
          <div className={classes.sorry}>Sorry</div>
          <div className={classes.sorryDesc}>Looks like something went wrong. Please try again or select a new tip method.</div>
          <div className={classes.spaceFiller}/>
          <PaymentMethod cardType={paymentMethods[chosenPaymentMethod].type} lastFour={paymentMethods[chosenPaymentMethod].lastFour} changePaymentMethod={() => setChoosePaymentMethodModal(true)}/>
          <BlueBar className={classes.bottomButton} onClick={tryAgain} width="full">
            <div>
              Try again
            </div>
          </BlueBar>
        </div>
      </Container>
      <ChoosePaymentMethodModal
        handleClose={onChoosePaymentMethodModalClose}
        currency='R'
        open={openChoosePaymentMethodModal}
        paymentMethods={paymentMethods}
        chosenPaymentMethod={chosenPaymentMethod}
        changePaymentMethod={changePaymentMethod}/>
      <TipLoading open={tipLoading}/>
    </React.Fragment>
)}
