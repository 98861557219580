import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Modal } from '@material-ui/core';

export default function TipLoading(props) {

  const useStyles = makeStyles((theme) => ({
    modalContainer: {
      width: '100%',
      height: '100%',
      bottom: '0px',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: theme.typography.fontFamily
    },
    loadingContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#000000',
      backgroundColor: '#FFFFFF',
      height: '100%',
    },
    loadingSpinner: {
      margin: '40px'
    },
    loadingText: {
      fontSize: '22px',
    }
  }
  ));

  const classes = useStyles();

  const {
    open,
  } = props;


  return (
    <Modal
      open={open}
      aria-labelledby="Processing"
      aria-describedby="The tip is processing"
      className={classes.modalContainer}
    >
      <div className={classes.loadingContainer}>
        <CircularProgress className={classes.loadingSpinner} size='110px' color="secondary" />
        <div className={classes.loadingText}>Please wait, processing tip ...</div>
      </div>
    </Modal>
  );
}

TipLoading.propTypes = {
  open: PropTypes.bool,
};
