import React from 'react';
import PropTypes from "prop-types";
import { useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles';
import { Container, IconButton, Avatar } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import CheckCircle from '@material-ui/icons/CheckCircleOutlined';
import AddComment from '@material-ui/icons/AddCommentOutlined';
import { t4cBlueColor } from 'assets/jss/styles';
import Confetti from 'components/Animations/Confetti'
import BlueBar from 'components/Buttons/BlueBar'
import RatingStars from 'components/Misc/RatingStars'
import { navigate } from "@reach/router"
import WhiteOutlined from 'components/Buttons/WhiteOutlined';
import CommentModal from 'components/Misc/CommentModal';
import { addOrUpdateRatingMutation } from 'gql/queries'
import { useSnackbar } from 'notistack';
import Person from '@material-ui/icons/Person'

export default function TipSuccess(props) {

  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      padding: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '@media (min-width: 600px)': {
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        border: '2px solid #E5E5E5',
      },
      fontFamily: theme.typography.fontFamily
    },
    header: {
      height: '56px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '22px',
    },
    headerButton: {
      right: '0px',
      position: 'absolute',
      height: '56px',
    },
    blueHeader: {
      backgroundColor: t4cBlueColor,
      flexBasis: "45%",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      color: '#FFFFFF',
      alignItems: 'center',
      position: 'relative',
    },
    whiteFooter: {
      backgroundColor: '#FFFFFF',
      flexBasis: '55%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '16px',
      minHeight: '320px',
    },
    centerIcon: {
      fontSize: '110px',
    },
    tipAmount: {
      fontSize: '64px',
      lineHeight: '48px',
      fontWeight: 500,
      marginBottom: '40px'
    },
    tippeeImage: {
      height: 80,
      width: 80,
      borderRadius: '50%',
      border: '2px white solid',
    },
    tippeeName: {
      marginLeft: '20px',
      fontSize: '24px',
      lineHeight: '20px',
      fontWeight: '300',
    },
    tippeeBio: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '16px',
    },
    bottomButton: {
      marginBottom: '16px',
    },
    ratingDesc: {
      textAlign: 'center',
      fontSize: '24px',
      lineHeight: '20px',
      fontWeight: '300',
      marginBottom: '8px',
    },
    starContainer: {
      marginBottom: '16px',
      textAlign: 'center',
    },
    profileCircle: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
      height: '80px',
      width: '80px',
    },
    profileIcon: {
      height: '65px',
      width: '65px',
    },
    spaceFiller: {
      flexGrow: 1
    },
    '@media (max-height: 670px)': {
      tipAmount: {
        marginBottom: '20px'
      }
    },
    '@media (max-height: 580px)': {
      centerIcon: {
        fontSize: '80px',
      },
      tipAmount: {
        fontSize: '48px',
        marginBottom: '10px'
      }
    },
  }));
  
  const classes = useStyles()

  const {
    payment,
    tippee
  } = props

  const [addOrUpdateRating, { loading: doingRating }] = useMutation(addOrUpdateRatingMutation)

  const [showCommentModal, setShowCommentModal] = React.useState(false)
  const [editComment, setEditComment] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    navigate(`/`)
  }

  const onCommentModalClose = (comment) => {
    setEditComment(comment)
    setShowCommentModal(false)
  }

  const currency = 'R'

  const doRating = (rating) => {
    addOrUpdateRating({
      variables: {
        input: {
          rating: rating,
          tippeeId: payment.tippee.id,
          tipperId: payment.tipper.id,
          paymentId: payment.id
        }
      },
    })
    .then( () => {
      enqueueSnackbar('Thank you for your rating!', { 
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    })
    .catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    })
  } 
  
  return (
    <React.Fragment>
      <Container maxWidth="sm" className={classes.mainContainer}>
        <div className={classes.grid + ' ' + classes.blueHeader}>
          <Confetti/>
          <div className={classes.header}>
            <IconButton onClick={() => handleClose(null)} aria-label="back" color="inherit" className={classes.headerButton}>
              <Close />
            </IconButton>
            <div className={classes.heading}>
              Thanks4Tipping
            </div>
          </div>
          <CheckCircle className={classes.centerIcon}/>
          <div className={classes.tipAmount}>
            {currency} {(payment.totalInCents/100).toFixed(2)}
          </div>
        </div>
        <div className={classes.grid + ' ' + classes.whiteFooter}>
        <div className={classes.spaceFiller}></div>
          <div className={classes.tippeeBio}>
            {tippee.profileImageUrl ? 
            (<img className={classes.tippeeImage} src={tippee.profileImageUrl} alt='Tippee' />) :
            (
              <Avatar className={classes.profileCircle}>
                <Person className={classes.profileIcon} />
              </Avatar>
            )}
            <div className={classes.tippeeName}>{tippee.shortFullName}</div>
          </div>
          <div className={classes.spaceFiller}></div>
          <div className={classes.starContainer}>
            <div className={classes.ratingDesc}>How did I do?</div>
            <RatingStars onRate={doRating} disable={doingRating}/>
          </div>
          <div className={classes.spaceFiller}></div>
          
          <WhiteOutlined startIcon={<AddComment/>} className={classes.bottomButton} onClick={() => setShowCommentModal(true)} width="fullWithMargin">
            <div>
              {editComment ? 'Edit comment' : 'Add comment'}
            </div>
          </WhiteOutlined>
          <BlueBar className={classes.bottomButton} onClick={handleClose} width="fullWithMargin">
            <div>
              Done
            </div>
          </BlueBar>
        </div>
      </Container>
      <CommentModal handleClose={onCommentModalClose} payment={payment} open={showCommentModal}/>
    </React.Fragment>
)}

TipSuccess.propTypes = {
  payment: PropTypes.object.isRequired,
  tippee: PropTypes.object.isRequired,
};