import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import QrReader from 'react-qr-reader'
import logo from 'assets/img/t4c-logo-white.png';
import bigLogo from 'assets/img/logo.png';
import { t4cBlueColor } from 'assets/jss/styles';
import { navigate } from "@reach/router"
import Url from 'url-parse'
import { Container } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks'
import { tippeeByMasterPassCodeMutation } from 'gql/queries'
import WhiteOutlined from 'components/Buttons/WhiteOutlined'
import { useSnackbar } from 'notistack';

export default function Home() {

  const useStyles = makeStyles((theme) => ({
    container: {
      backgroundColor: t4cBlueColor,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      height: '32px'
    },
    bigLogo: {
      width: '90%',
      marginTop: '54px',
      border: '10px solid white',
    },
    logoContainer: {
      height: '76px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: '100%',
    },
    rightButton: {
      height: '76px',
      width: '76px',
      color: '#FFFFFF',
      position: 'absolute',
      right: 0,
      marginRight: '-16px',
    },
    tooltip: {
      width: '187px',
      background: 'rgba(0, 0, 0, 0.64)',
      padding: '8px 16px',
      marginTop: '40px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& div': {
        fontSize: '14px',
        lineHeight: '15px',
        color: '#FFFFFF',
        fontFamily: theme.typography.fontFamily
      },
    },
    scanButton: {
      marginTop: '24px'
    },
  }));
  
  const classes = useStyles();

  const [tippeeByMasterPassCode] = useMutation(tippeeByMasterPassCodeMutation);
  const [showCamera, setShowScanner] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleError = (error) => {
    console.log(error.message)
    enqueueSnackbar('We need to access your camera to scan the QR code', { 
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
    setShowScanner(false)
  }

  const handleScan = (code) => {
    if (code) {
      var url = new Url(code,true);
      if (url.hostname.includes('snapscan')) {
        if (url.query.tippeeId) navigate(`/tip/${url.query.tippeeId}`, { replace: false })
      } else if (url.hostname.includes('masterpass') && url.query.c) {
        tippeeByMasterPassCode({
          variables: {
            input: {
              code: url.query.c
            }
          },
        })
        .then((data) => {
          navigate(`/tip/${data.tippeeByMasterPassCode.id}`, { replace: false })
        })
        .catch((error) => {
          console.log(error)
        })
        if (url.query.c) navigate(`/tip/masterpass/${url.query.c}`, { replace: false })
      }
    }
  }

  if (showCamera) return (
    <Container maxWidth="sm" className={classes.container}>
      <div className={classes.logoContainer}>
        <img alt='logo' className={classes.logo} src={logo}></img>
      </div>
      <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={
            {
              width: '90%',
              marginTop: '54px',
            }}
          showViewFinder={false}
          className='qr-frame'
        />
      <div className={classes.tooltip}>
        <div>Scan a Tip4Change QR code to tip</div>
      </div>
    </Container>
  )
  
  return (
    <Container maxWidth="sm" className={classes.container}>
      <div className={classes.logoContainer}>
        <img alt='logo' className={classes.logo} src={logo}></img>
      </div>
      <img alt='big logo' className={classes.bigLogo} src={bigLogo}></img>
      <div className={classes.tooltip}>
        <div>Press the button below to scan your Tippee's QR code with your phone's camera</div>
      </div>
      <WhiteOutlined className={classes.scanButton} onClick={() => setShowScanner(true)}>
        <div>
          Scan QR
        </div>
      </WhiteOutlined>
    </Container>
)}
