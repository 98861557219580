import React from 'react';
import './App.css';
import { Router } from "@reach/router";
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './assets/jss/styles'
import { ApolloProvider } from '@apollo/react-hooks';
import { SnackbarProvider } from 'notistack';
import Home from './views/Home/Home'
import Tip from './views/Tip/Tip'
import TipRedirect from './views/Tip/TipRedirect'
import TipSuccessSnapscan from './views/Tip/TipSuccessSnapscan'
import TipSuccessGeneric from './views/Tip/TipSuccessGeneric'
import Login from './views/Login/Login'
import LoginEmail from './views/Login/LoginEmail'
import Profile from './views/Account/Profile'
import EditProfile from './views/Account/EditProfile'
import EditPassword from './views/Account/EditPassword'
import TipFailure from 'views/Tip/TipFailure'

import ApolloClient from 'apollo-boost'
import { typeDefs, resolvers } from "./gql/resolvers"
import { InMemoryCache } from "apollo-cache-inmemory"

const cache = new InMemoryCache()

const client = new ApolloClient({
  cache,
  uri: `${process.env.REACT_APP_T4C_API_ADDR}/graphql`,
  resolvers,
  typeDefs
});


const App = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          maxSnack={3}>
          <Router>
            <Home path="/" />
            <Login path="/login" />
            <LoginEmail path="/login/email" />
            <Tip path="/tip/:tippee" />
            <TipSuccessSnapscan path="/tip/:processor/success" />
            <TipRedirect path="/tip/:tippee/:processor/redirect" />
            <TipFailure path="/tip/:tippee/:processor/failed" />
            <TipSuccessGeneric path="/tip/success/:transactionId" />
            <Profile path="/account/profile" />
            <EditPassword path="/account/profile/edit/password"/>
            <EditProfile path="/account/profile/edit/:field"/>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
)}

export default App;
