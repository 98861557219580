import { createMuiTheme } from '@material-ui/core/styles';

const t4cBlueColor = '#35a8e0'
const t4cYellowColor = '#fcdb00'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: t4cYellowColor,
    },
    secondary: {
      main: t4cBlueColor,
    },
    white: {
      main: '#000000',
    },
  },
  typography: {
    fontFamily: [
      'Betm',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export {
  theme,
  t4cBlueColor,
  t4cYellowColor
}