import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBack from "@material-ui/icons/ArrowBack";
import { IconButton, List, ListItem, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { navigate } from "@reach/router"
import { useLocation } from "@reach/router"
import { parse } from "query-string"

export default function Profile() {

  const useStyles = makeStyles((theme) => ({
    container: {
      width: '100%',
      height: '100%',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 300,
    },
    header: {
      backgroundColor: theme.palette.secondary.main,
      fontSize: '36px',
      color: '#FFFFFF'
    },
    headerText: {
      padding: '16px',
    },
    listText: {
      '& > span.MuiTypography-displayBlock': {
        fontSize: '20px'
      }
    },
    listIcon: {
      color: theme.palette.secondary.main,
    },
    listIconSecondary: {
      color: '#000000'
    },
    topList: {
      marginBottom: 'auto',
    },
    listItem: {
      flexDirection: 'column',
      alignItems: 'baseline',
      paddingBottom: '16px',
      paddingTop: '16px',
    },
    listItemHeader: {
      fontFamily: 'Betm',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '12px',
      color: '#989898',
      marginBottom: '12px'
    },
    listItemText: {
      fontFamily: 'Betm',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '22px',
      lineHeight: '16px',
    }
  }));
  
  const classes = useStyles();

  const [changesSaved, setChangesSaved] = React.useState(false);

  const location = useLocation()

  const searchParams = parse(location.search)

  React.useEffect(() => {
    if (searchParams.saved) setChangesSaved(true)
  }, [searchParams.saved])

  const handleCloseSnack = () => {
    setChangesSaved(false)
  };
  
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <IconButton onClick={() => navigate(`/`)} aria-label="account" color="inherit" className={classes.headerText}>
          <ArrowBack />
        </IconButton>
        <div className={classes.headerText}>Account</div>
      </div>
      <List className={classes.topList}>
        <ListItem button onClick={() => navigate(`/account/profile/edit/first-name`)} className={classes.listItem}>
          <div className={classes.listItemHeader}>First Name</div>
          <div className={classes.listItemText}>Olive</div>
        </ListItem>
        <ListItem button onClick={() => navigate(`/account/profile/edit/last-name`)} className={classes.listItem}>
          <div className={classes.listItemHeader}>Last Name</div>
          <div className={classes.listItemText}>Williams</div>
        </ListItem>
        <ListItem button onClick={() => navigate(`/account/profile/edit/email`)} className={classes.listItem}>
          <div className={classes.listItemHeader}>Email</div>
          <div className={classes.listItemText}>olive.w@gmail.com</div>
        </ListItem>
        <ListItem button onClick={() => navigate(`/account/profile/edit/password`)} className={classes.listItem}>
          <div className={classes.listItemHeader}>Password</div>
          <div className={classes.listItemText}>••••••••••••</div>
        </ListItem>
      </List>
      <Snackbar open={changesSaved} autoHideDuration={6000} onClose={handleCloseSnack}>
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnack} severity="success">
          Changes Saved
        </MuiAlert>
      </Snackbar>
    </div>
)}
