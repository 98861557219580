import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import CheckCircle from '@material-ui/icons/CheckCircleOutlined';
import { t4cBlueColor } from 'assets/jss/styles';
import Confetti from 'components/Animations/Confetti'
import BlueBar from 'components/Buttons/BlueBar'
import { navigate } from "@reach/router"

export default function TipSuccessNoData(props) {

  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      padding: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '@media (min-width: 600px)': {
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        border: '2px solid #E5E5E5',
      },
      fontFamily: theme.typography.fontFamily
    },
    header: {
      height: '56px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '22px',
    },
    headerButton: {
      right: '0px',
      position: 'absolute',
      height: '56px',
    },
    blueHeader: {
      backgroundColor: t4cBlueColor,
      height: '345px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      color: '#FFFFFF',
      alignItems: 'center',
      position: 'relative',
    },
    whiteFooter: {
      backgroundColor: '#FFFFFF',
      flexBasis: '55%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    centerIcon: {
      fontSize: '110px',
    },
    tipAmount: {
      fontSize: '64px',
      lineHeight: '48px',
      fontWeight: 500,
      marginBottom: '40px'
    },
    centreTextContainer: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      fontSize: '24px',
      margin: '16px 16px 0px 16px',
      textAlign: 'center',
      '& > div': {
        marginBottom: '16px'
      }
    },
    bottomButton: {
      marginBottom: '16px',
    },
  }));
  
  const classes = useStyles()

  const handleClose = () => {
    navigate(`/`)
  }

  
  return (
    <React.Fragment>
      <Container maxWidth="sm" className={classes.mainContainer}>
        <div className={classes.grid + ' ' + classes.blueHeader}>
          <Confetti/>
          <div className={classes.header}>
            <IconButton onClick={() => handleClose(null)} aria-label="back" color="inherit" className={classes.headerButton}>
              <Close />
            </IconButton>
            <div className={classes.heading}>
              Thanks4Tipping
            </div>
          </div>
          <CheckCircle className={classes.centerIcon}/>
          <div className={classes.tipAmount}>
            Tip Complete
          </div>
        </div>
        <div className={classes.grid + ' ' + classes.whiteFooter}>
          <div className={classes.centreTextContainer}>
            <div>You will receive a receipt via email</div>
            <div>You can rate and review your Tippee from the receipt</div>
            <div>Thank You!</div>
          </div>
          <BlueBar className={classes.bottomButton} onClick={handleClose} width="fullWithMargin">
            <div>
              Done
            </div>
          </BlueBar>
        </div>
      </Container>
    </React.Fragment>
)}

TipSuccessNoData.propTypes = {
};