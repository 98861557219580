import gql from "graphql-tag"
import * as ls from 'local-storage'
import { customerDetailsQuery } from './queries'

export const typeDefs = gql`
  extend type Query {
    customerDetails: CustomerDetails!
  }

  type CustomerDetails {
    email: String!
    lastTipAmount: Number
    snapscanRef: String
  }

  type CreateRatingInput {
    rating: String!
    tippeeId: String!
    customerId: String!
    paymentId: String!
  }

  type CreateCommentInput {
    comment: String!
    tippeeId: String!
    customerId: String!
    paymentId: String!
  }

  extend type Mutation {
    addOrUpdateCustomer(input: CustomerDetails!): CustomerDetails!
    addRating(input: CreateRatingInput!): CreateRatingInput!
  }
`;

const CUSTOMER_PATH = 't4c.customer'

export const resolvers = {
  Query: {
    async customerDetails(_root, _input, { cache }) {
      try {
        const { cartCustomer } = cache.readQuery({
          query: customerDetailsQuery,
        })
        return cartCustomer
      } catch (error) {
        const lsData = ls.get(CUSTOMER_PATH) || {
          email: '',
          lastTipAmount: '',
          snapscanRef: '',
          __typename: 'CustomerDetails',
        }
        return lsData
      }
    },
  },
  Mutation: {
    addOrUpdateCustomer: (
      _root,
      { input },
      { cache },
    ) => {
      const CustomerDetails = {
        ...input,
        __typename: 'CustomerDetails',
      }
      const data = {
        CustomerDetails,
      }

      cache.writeQuery({ data, query: customerDetailsQuery })
      ls.set(CUSTOMER_PATH, CustomerDetails)
    },
  }
};