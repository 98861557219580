import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Lottie from 'react-lottie'
import animationData from 'assets/lottie/7893-confetti-cannons.json'

export default function Confetti() {
  
  const useStyles = makeStyles((theme) => ({
      animation: {
        position: 'absolute',
        height: '100%',
        width: '100%',
      }
    }
  ));

  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={classes.animation}>
      <Lottie options={defaultOptions}
        height='100%'
        width='100%'
      />
    </div>
    
  );
}
