import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Modal, Slide } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Backspace from '@material-ui/icons/Backspace';
import Button from "@material-ui/core/Button";
import T4CButton from 'components/Buttons/T4CButton'

export default function CustomTipSheet(props) {

  const [customTip, setCustomTip] = React.useState(0);
  
  const useStyles = makeStyles((theme) => ({
      modalContainer: {
        width: '100%',
        height: '100%',
        bottom: '0px',
        display: 'flex',
        flexDirection: 'column',
        color: '#000000',
        fontFamily: theme.typography.fontFamily
      },
      formContainer: {
        backgroundColor: 'white',
        marginTop: 'auto',
      },
      formHeader: {
        borderBottom: '2px #E5E5E5 solid',
        height: '62px',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '22px',
        alignItems: 'center',
        paddingLeft: '16px',
      },
      formHeaderText: {
        flexGrow: 1,
      },
      amountContainer: {
        height: '88px',
        width: '280px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        position: 'relative',
        borderBottom: `2px ${theme.palette.secondary.main} solid`,
      },
      currency: {
        fontSize: '36px',
        lineHeight: '36px',
        color: theme.palette.secondary.main,
        position: 'absolute',
        left: '0px',
        bottom: '19px'
      },
      amount: {
        fontSize: '64px',
        lineHeight: '64px',
      },
      keypadContainer: {
        margin: '16px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
      },
      button: {
        fontSize: '24px',
        width: '99px',
        height: '40px',
        borderRadius: '0px',
        backgroundColor: '#FFFFFF',
        margin: '8px',
        '&.Mui-disabled': {
          backgroundColor: '#FFFFFF',
        }
      },
      doneButton: {
        marginTop: '8px',
        marginBottom: '16px',
      },
    }
  ));

  const classes = useStyles();

  const createDigits = () => {
    let buttons = []

    for (let i = 1; i <= 9; i++) {
      buttons.push(<Button key={i} onClick={() => pressDigit(i)} className={classes.button} variant="contained" color="secondary" disableElevation>{i}</Button>)
    }
    return buttons
  }

  const pressDigit = (digit) => {
    setCustomTip((customTip) => {
      if (digit === 'back') {
        return Math.floor(customTip/10)
      } else if (customTip > 99999) return customTip
      customTip = customTip + digit.toString()
      return parseFloat(customTip)
    })
  }

  const {
    open,
    handleClose,
    currency
  } = props;


  return (
    <Modal
      open={open}
      aria-labelledby="Custom Tip"
      aria-describedby="Enter your custom tip in the dialogue"
      className={classes.modalContainer}
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <div className={classes.formContainer}>
          <div className={classes.formHeader}>
            <div className={classes.formHeaderText}>Enter custom amount</div>
            <IconButton onClick={() => handleClose(null)} aria-label="back" color="inherit" className={classes.leftButton}>
              <Close />
            </IconButton>
          </div>
          <div className={classes.amountContainer}>
            <div className={classes.currency}>{currency}</div>
            <div className={classes.amount}>{(customTip/100).toFixed(2)}</div>
          </div>
          <div className={classes.keypadContainer}>
            {createDigits()}
            <Button key={'.'} disabled onClick={() => pressDigit('.')} className={classes.button} variant="contained" color="secondary" disableElevation></Button>
            <Button key={'0'} onClick={() => pressDigit('0')} className={classes.button} variant="contained" color="secondary" disableElevation>{'0'}</Button>
            <IconButton key={'back'} onClick={() => pressDigit('back')} className={classes.button} variant="contained" color="secondary" ><Backspace/></IconButton>
          </div>
          <T4CButton className={classes.doneButton} onClick={() => handleClose(customTip)} width="fullWithMargin" type="secondary">
            <div>
              Done
            </div>
          </T4CButton>
        </div>
      </Slide>
    </Modal>
  );
}

CustomTipSheet.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  currency: PropTypes.string
};
