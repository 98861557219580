import React, { useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { SUCCESSFUL_SNAPSCAN_QUERY, customerDetailsQuery, addOrUpdateCustomerMutation } from 'gql/queries'
import TipLoading from 'components/Misc/TipLoading'
import TipSuccessNoData from 'components/Tip/TipSuccessNoData'
import Url from 'url-parse'

export default function TipSuccessGeneric(props) {

  const [addOrUpdateCustomer] = useMutation(addOrUpdateCustomerMutation);

  const { loading: loadingCustomer, error: customerError, data: customer } = useQuery(customerDetailsQuery);

  const { loading, error, data, refetch } = useQuery(SUCCESSFUL_SNAPSCAN_QUERY,
    {
      variables: { id: customer?.customerDetails?.snapscanRef },
      skip: loadingCustomer || customerError || !customer?.customerDetails?.snapscanRef
    })

  const [retryCounter, setRetryCounter] = React.useState(5);
  const [unknownPayment, setUnknownPayment] = React.useState(false);
  const countRef = useRef(retryCounter);
  countRef.current = retryCounter;

  useEffect(() => {
    if (!data?.paymentBySnapscanRef?.payment) {
      if (countRef.current === 0) {
        setUnknownPayment(true)
      } else {
        const timer = setTimeout(() => {
          setRetryCounter(countRef.current-1)
          refetch()
          .catch( () => {
          })
        }, 1000);
        return () => clearTimeout(timer);
      }
    }
    // eslint-disable-next-line
  }, [retryCounter, error]);

  useEffect(() => {
    if (data?.paymentBySnapscanRef?.payment) {
      addOrUpdateCustomer({
        variables: {
          input: {
            ...customer.customerDetails,
            snapscanRef: ''
          }
        },
      })
      .then(() => {
        var url = new Url(window.location.href,true)
        window.location.href = url.protocol + '//' + url.host + '/tip/success/' + data.paymentBySnapscanRef.payment.id
      })
      .catch((error) => {
        console.log(error)
      })
    }
    // eslint-disable-next-line
  }, [data?.paymentBySnapscanRef?.payment]);

  if ( loading || error || loadingCustomer || customerError || !unknownPayment )
    return (
      <TipLoading open={true}/>
    )
  
  return (
    <TipSuccessNoData/>
)}
