import React from 'react';
import logo from 'assets/img/logo.png';
import { ReactComponent as Click } from 'assets/img/click.svg';
import { ReactComponent as GoogleLogo } from 'assets/img/google-icon.svg';
import { ReactComponent as FacebookLogo } from 'assets/img/facebook-logo.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { t4cBlueColor } from 'assets/jss/styles'
import WhiteOutlined from 'components/Buttons/WhiteOutlined'
import BlueLink from 'components/Link/BlueLink'

export default function Login() {

  const useStyles = makeStyles((theme) => ({
    root: {
      height: '100%',
      fontFamily: '"Betm", "Helvetica", "Arial", sans-serif',
    },
    mainContainer: {
      padding: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '@media (min-width: 600px)': {
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        border: '2px solid #E5E5E5',
      }
    },
    
    blueHeader: {
      backgroundColor: t4cBlueColor,
      flexBasis: '45%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    whiteFooter: {
      backgroundColor: '#FFFFFF',
      flexBasis: '55%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    grid: {
      textAlign: 'center',
    },
    logo: {
      height: '60px',
    },
    centreImage: {
      height: '250px',
    },
    logoText: {
      fontSize: '40px',
      color: '#FFFFFF'
    },
    logoSubText: {
      fontSize: '20px',
      color: '#000000',
      fontWeight: 300
    },
    textHeader: {
      fontWeight: '400',
      fontSize: '28px',
      lineHeight: '28px',
      textAlign: 'center',
      letterSpacing: '-0.03em',
      color: '#000000',
      marginTop: '16px',
    },
    textSubHeader: {
      fontWeight: '300',
      fontSize: '24px',
      lineHeight: '28px',
      textAlign: 'center',
      letterSpacing: '-0.03em',
      color: '#000000',
      marginTop: '16px',
      width: '312px'
    },
    textLogin: {
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '20px',
      textAlign: 'center',
      letterSpacing: '-0.03em',
      color: '#000000',
      marginTop: '10px',
    },
    logoContainer: {
      flexGrow: 1,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
    },
    imageContainer: {
      height: '175px',
      '& svg': {
        height: '100%',
      },
    },
    loginButtonContainer: {
      marginTop: '6px',
    },
    loginButton: {
      margin: '10px 0px',
    },
    grayButton: {
      width: '312px',
    }
  }));
  
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.mainContainer}>
        <div className={classes.grid + ' ' + classes.blueHeader}>
          <div className={classes.logoContainer}>
            <div>
              <img className={classes.logo} src={logo} alt='logo' />
            </div>
            <div className={classes.logoText}>Tip4Change</div>
            <div className={classes.logoSubText}>Cashless Gratitude</div>
          </div>
          <div className={classes.imageContainer}>
            <Click/>
          </div>
        </div>
        <div className={classes.grid + ' ' + classes.whiteFooter}>
          <div className={classes.textHeader}>"No cash? No problem."</div>
          <div className={classes.textSubHeader}>"The fastest, simplest way to tip when you don’t have cash."</div>
          <div className={classes.loginButtonContainer}>
            <WhiteOutlined className={classes.loginButton + ' ' + classes.grayButton} farLeftIcon={true}>
              <GoogleLogo/>
              <div>
                Sign up with Google
              </div>
            </WhiteOutlined>
            <WhiteOutlined className={classes.loginButton + ' ' + classes.grayButton} farLeftIcon={true}>
              <FacebookLogo/>
              <div>
                Sign up with Facebook
              </div>
            </WhiteOutlined>
            <WhiteOutlined className={classes.loginButton + ' ' + classes.grayButton} farLeftIcon={true}>
              <div>
                Sign up with email
              </div>
            </WhiteOutlined>
          </div>
          
          <div className={classes.textLogin}>
            Already have an account? <BlueLink to="email">Sign in</BlueLink>
          </div>
        </div>
      </Container>
      </div>
)}
