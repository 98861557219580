import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Header from 'components/Header/Header'
import BlueBar from 'components/Buttons/BlueBar'
import SocialDouble from 'components/Buttons/SocialDouble'
import { ReactComponent as GoogleLogo } from 'assets/img/google-icon.svg';
import { ReactComponent as FacebookLogo } from 'assets/img/facebook-logo.svg';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import BlueLink from 'components/Link/BlueLink'
import { navigate } from "@reach/router"
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoginEmail() {

  const [showPassword, setShowPassword] = React.useState(false);

  const EmailSignUpValidation = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required('Required'),
    password: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  })

  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      padding: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '@media (min-width: 600px)': {
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        border: '2px solid #E5E5E5',
      }
    },
    socialButtonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '24px',
      marginBottom: '24px',
    },
    form: {
      marginBottom: '24px',
    },
    field: {
      fontSize: '22px',
      lineHeight: '24px',
      letterSpacing: '-0.03em',
    },
    signUpContainer: {
      padding: "0px 24px",
    },
    forgotLinkContainer: {
      textAlign: 'center',
      marginTop: '20px',
    },
    spinner: {
      color: 'gray'
    }
  }));
  
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => {
      return !showPassword
    })
  };
  
  return (
    <Container maxWidth="sm" className={classes.mainContainer}>
      <Header size="std" color="primary" headerText="Sign In"/>
      <div className={classes.signUpContainer}>
        <div className={classes.socialButtonContainer}>
          <SocialDouble>
            <GoogleLogo/>
            <div>
              Sign in
            </div>
          </SocialDouble>
          <SocialDouble>
            <FacebookLogo/>
            <div>
              Sign in
            </div>
          </SocialDouble>
        </div>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={EmailSignUpValidation}
          onSubmit={(values) => {
            console.log(values)
            setTimeout(() => {
              navigate(`/`)
            }, 2000);
          }}
        >
          {({ submitForm, isSubmitting, isValid, dirty }) => (
            <div>
              <Form className={classes.form}>
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  label="Email Address"
                  fullWidth
                  color="secondary"
                  InputProps={{
                    className: classes.field
                  }}
                />
                <Field
                  component={TextField}
                  name="password"
                  label="Password"
                  fullWidth
                  color="secondary"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    className: classes.field
                  }}
                />
              </Form>
              <BlueBar startIcon={isSubmitting && <CircularProgress className={classes.spinner} size='36px'/>} onClick={submitForm} width="full" disabled={!(isValid && dirty)}>
                <div>
                  {isSubmitting ? 'Signing in' : 'Sign in'}
                </div>
              </BlueBar>
            </div>
          )}
        </Formik>
        <div className={classes.forgotLinkContainer}>
          <BlueLink to="login/forgot">Forgot your password?</BlueLink>
        </div>
      </div>
      
    </Container>
)}
