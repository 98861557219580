import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "@reach/router"
import { t4cBlueColor } from "assets/jss/styles";

export default function BlueLink(props) {
  
  const useStyles = makeStyles((theme) => ({
      link: {
        color: t4cBlueColor,
        textDecoration: 'none',
        fontWeight: 500,
      }
    }
  ));

  const classes = useStyles();

  const {
    className,
    children,
    ...rest
  } = props;
  const headerClasses = classNames({
    [className]: className,
    [classes.link]: true,
  });
  return (
    <Link {...rest} className={headerClasses}>
      {children}
    </Link>
  );
}

BlueLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
