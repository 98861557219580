import React, { useEffect, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks'
import Url from 'url-parse'
import TipLoading from 'components/Misc/TipLoading'
import { SUCCESSFUL_PAYMENT_BY_PROCESSOR_QUERY } from 'gql/queries'

export default function TipRedirect(props) {

  var url = new Url(window.location.href,true)
  const currentUrl = url.host + url.pathname

  const { error, data, refetch } = useQuery(SUCCESSFUL_PAYMENT_BY_PROCESSOR_QUERY,
    {
      variables: {
        id: url.query.transactionId,
        processor: props.processor.toUpperCase(),
      },
      skip: props.processor !== 'masterpass' && url.query.status !== 'SUCCESS'
    })

  const [retryCounter, setRetryCounter] = React.useState(10)
  const countRef = useRef(retryCounter)
  countRef.current = retryCounter

  useEffect(() => {
    if (props.processor === 'masterpass') {
      if (url.query.status === 'SUCCESS') {
        console.log(error)
        console.log(data)
        if (error) {
          if (countRef.current === 0) {
            const currentUrl = url.host + url.pathname.substr(0,url.pathname.lastIndexOf('/'))
            window.location.href = url.protocol + '//' + currentUrl + '/failed'
          } else {
            const timer = setTimeout(() => {
              setRetryCounter(countRef.current-1)
              refetch()
              .catch( () => {
              })
            }, 1000);
            return () => clearTimeout(timer);
          }
        } else if (data) {
          window.location.href = url.protocol + '//' + url.host + '/tip/success/' + data.paymentByProcessorId.id
        }
      } else if (url.query.reason === 'Cancelled') {
        window.location.href = url.protocol + '//' + currentUrl.replace('/masterpass/redirect','')
      } else {
        window.location.href = url.protocol + '//' + currentUrl.replace('redirect','failed')
      }
    }
    // eslint-disable-next-line
  }, [retryCounter, error, data]);

  return (
    <TipLoading open={true}/>
  )
}